import '../../styles/styles.css'
import '../../styles/carousel.css'

import App from 'next/app'
import * as React from 'react'
import Head from 'next/head'
import { Toaster } from 'react-hot-toast';

export const CUSTOMER_WEBAPP_URL = process.env.CUSTOMER_WEBAPP_URL


class NuBrakesApp extends App<any, any, any> {
  constructor(props) {
    //add app props here? or change from extends app?
    super(props)
  }

  render() {
    const { Component, pageProps } = this.props
    return (
      <>
        <Head>
          <title>NuBrakes | Mobile Brake Repair</title>
          <link rel={'shortcut icon'} href={'/img/favicon.ico'} />
        </Head>
        <Component {...pageProps} />
        <Toaster />
      </>
    )
  }
}

export default NuBrakesApp
